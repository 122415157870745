<template>
  <FormInputWidget class="form-input-markdown" :notRequired="notRequired" :label="label" :hideLabel="hideLabel" :hasError="hasError" :errorMsg="errorMsg">
    <template>
      <Editor ref="editor" :initialValue="value" initialEditType="wysiwyg" :options="editorOptions" @change="editorContentChanged" @load="editorLoaded" />
    </template>
  </FormInputWidget>
</template>

<script>

import formInputWidgetMixin from '@/libs/Form/components/Inputs/mixins/formInputWidget';
import formInputFieldMixin from '@/libs/Form/components/Inputs/mixins/formInputField';

import FormInputWidget from '@/libs/Form/components/Inputs/Widgets/FormInputWidget.vue';
import { Editor } from '@toast-ui/vue-editor';
import '@toast-ui/editor/dist/i18n/it-it.js';

export default {
  mixins: [formInputWidgetMixin, formInputFieldMixin],
  components: {
    FormInputWidget,
    Editor,
  },
  data () {
    return {
      lastValue: null,
      editor: null,
      editorOptions: {
        language: 'it-IT',
        usageStatistics: false,
        hideModeSwitch: true,
        toolbarItems: [
          ['bold', 'italic'],
          ['quote', 'ul', 'ol'],
        ],
      },
    };
  },
  watch: {
    value (newValue) {
      if (newValue === this.lastValue) {
        return;
      }

      this.editor.invoke('setMarkdown', newValue || '', false);
    },
  },
  methods: {
    editorLoaded () {
      this.editor = this.$refs.editor;
    },
    editorContentChanged () {
      if (!this.editor) {
        return;
      }

      this.lastValue = this.editor.invoke('getMarkdown');
      this.$emit('input', this.lastValue);
    },
  },
};

</script>

<style lang="scss">

@import '~@toast-ui/editor/dist/toastui-editor.css';

.form-input-markdown {
  .toastui-editor-contents {
    font-family: $headings-font-family;
    font-size: 1rem;
    padding: $input-padding-y $input-padding-x;
  }
}

</style>
